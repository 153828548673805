/* eslint-disable camelcase */
import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';
import ProjectInfo from '../slices/ProjectInfo';
import LargeImageText from '../slices/LargeImageText';

const IS_BROWSER = typeof window !== 'undefined';

const Project = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicProjects } = data;
  const env = process.env.GATSBY_SITE_NAME;
  const isFilmSite = env === 'films';

  const { data: pageData } = prismicProjects;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;

  const {
    content,
    title: projectTitle,
    video_cta_text1: cta_link,
    video_cta_text1: cta_text,
    video_cta_link,
    video_cta_text,
    video_text_alignment: text_alignment,
    video_link,
    title_colour: video_title_colour,
    video_image: image,
    video_title: videoTitle,
    video_aspect_ratio,
  } = pageData;

  const videoData = {
    primary: {
      title: videoTitle,
      video_aspect_ratio,
      content,
      cta_text,
      text_alignment,
      video_link,
      video_title_colour,
      image,
      video_cta_link,
      video_cta_text,
    },
  };
  const isFilms = process.env.GATSBY_SITE_NAME === 'films';
  const gallerySlices = sliceData?.filter((item) => item?.slice_type === 'gallery_grid' && item);

  // const [firstSlice] = gallerySlices || {};
  // const [lastSlice] = gallerySlices?.reverse() || {};

  const seo = {
    title: metaTitle?.text?.length > 2 ? metaTitle?.text : projectTitle.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
  };

  const removeBlogFromFilmsProjects = (_slice) => {
    const projectSlug = location?.pathname.includes('project/');
    const filterParam = isFilmSite && _slice?.props?.data?.slice_type === 'two_cols_text_list' && projectSlug;

    if (!filterParam) {
      return _slice;
    }
  };

  return (
    <Layout location={location} seo={seo} isFilms={isFilms} paddingBottom={isFilmSite}>
      <LargeImageText data={videoData} firstSlice isProject isFilms={isFilms} />
      <ProjectInfo data={pageData} isFilms={isFilms} />
      {!!sliceData?.length &&
        sliceData
          .map((slice, index) => (
            <Slice
              key={slice.id}
              data={slice}
              project={prismicProjects}
              firstSlice={index === 0}
              lastSlice={index === sliceData?.length - 1}
              isProject
              isFilms={isFilms}
            />
          ))
          .filter((_slice) => removeBlogFromFilmsProjects(_slice))}
    </Layout>
  );
};

export default Project;

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProjects(uid: { eq: $uid }) {
      id
      url
      prismicId
      data {
        page_type
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...LargeImageTextFragmentProject
          ...ShowcaseFragmentProject
          ...TwoColsTextListFragmentProject
          ...GalleryGridFragmentProject
          ...ImageTextFragmentProject
        }

        video_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        video_link {
          url
        }
        video_title {
          html
          text
        }
        video_title_colour

        cta_link {
          url
        }
        cta_text {
          html
          text
        }
        description {
          html
          text
        }
        industries {
          industry {
            html
            text
          }
        }
        products {
          product {
            html
            text
          }
        }
        professionals {
          profession {
            html
            text
          }
        }
        recognitions {
          recognition {
            html
            text
          }
        }
        schema {
          html
          text
        }
        services {
          service {
            html
            text
          }
          service_select
        }
        title {
          html
          text
        }
        video_aspect_ratio
      }
    }
  }
`;
