import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

const Page = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });

  const env = process.env.GATSBY_SITE_NAME;
  const isFilmSite = env === 'films';
  const isContentSite = env === 'content';

  const { prismicPage } = data;
  const { data: pageData, uid } = prismicPage;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    custom_footer_tagline: customFooterTagline,
    has_squiggles_above_footer: hasSquigglesAboveFooter,
    squiggles,
    prismic_reference: prismicRefrence,
    schema,
    indexed,
  } = pageData;

  const seo = {
    title: metaTitle.text || prismicRefrence?.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
    indexed,
  };

  const gallerySlices = sliceData?.filter((item) => item?.slice_type === 'gallery_grid' && item);

  const [firstSlice] = gallerySlices || {};
  const [lastSlice] = gallerySlices?.reverse() || {};

  // check if uid exists in list of pages with graphic
  const hasBg = (pageList) => pageList?.indexOf(uid) !== -1;

  // graphics AGENCY
  const agencyBgElements = {
    writingLeft: hasBg(['agency']),
    pinkGraffitiRight: hasBg(['agency']),
    writingBottom: hasBg(['landing-films', 'agency', 'films']),
    wolfPaint: hasBg(['what-we-do']),
    yellowSquiggle: hasBg(['what-we-do']),
  };

  // graphics FILM
  const filmBgElements = {
    smileyRight: hasBg(['who-we-are', 'melbourne']),
    filmReel: hasBg(['who-we-are', 'films', 'what-we-do-films']),
    filmWriting: hasBg(['who-we-are']),
    writingRight: hasBg(['films', 'what-we-do-films']),
    yellowSplatterLeft: hasBg(['films']),
    greyGraffitiBottom: hasBg(['films', 'what-we-do-films']),
    yellowSplatterRight: hasBg(['what-we-do-films']),
    yellowGraffitiWords: hasBg(['what-we-do-films']),
    filmWritingLeft: hasBg(['films']),
  };

  // add bg els based on SITE_NAME env
  const backgroundEls = {
    films: filmBgElements,
    agency: agencyBgElements,
    content: {},
  };

  const footerTaglineObj = {
    text: customFooterTagline?.text || '',
    uid: `/${uid}/`,
  };

  return (
    <Layout
      location={location}
      seo={seo}
      hasSquigglesAboveFooter={hasSquigglesAboveFooter}
      squiggles={squiggles}
      isFilms={isFilmSite}
      bgElements={backgroundEls[env]}
      hideEscapeRoutes={isContentSite}
      paddingBottom={isFilmSite}
      customFooterTagline={footerTaglineObj}
    >
      {sliceData.map((slice, index) => (
        <Fragment key={slice?.id}>
          <Slice
            data={slice}
            page={prismicPage}
            firstSlice={index === 0}
            lastSlice={index === sliceData?.length - 1}
            location={location}
            isFilms={isFilmSite}
          />
        </Fragment>
      ))}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        indexed
        custom_footer_tagline {
          html
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        prismic_reference {
          text
        }
        schema {
          text
        }
        body {
          ...LargeImageTextFragment
          ...ImageTextFragment
          ...TwoColsTextListFragment
          ...HeadlineTextFragment
          ...TwoColsTextCtaFragment
          ...TwoColsTextFilterFragment
          ...ShowcaseFragment
          ...GalleryGridFragment
          ...ThreeByThreeImageGridFragment
          ...CtaColsFragment
          ...FullscreenTwoColsFragment
          ...TwoColsTextImageFragment
          ...TextImageWithVideoStripFragment
        }
      }
    }
  }
`;
