import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, Form } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

const BlogPost = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });

  const { prismicBlogPost } = data || {};
  const { data: pageData } = prismicBlogPost || {};

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    description,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle?.text?.length > 2 ? metaTitle?.text : description,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };
  const isFilms = process.env.GATSBY_SITE_NAME === 'films';

  return (
    <Layout location={location} seo={seo} isFilms={isFilms}>
      {!!sliceData?.length &&
        sliceData.map((slice) => (
          <Slice key={slice.id} data={slice} blogPost={prismicBlogPost} isBlogPost isFilms={isFilms} />
        ))}
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      url
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        description
        body {
          ...LargeImageTextFragmentBlogPost
          ...TwoColsTextCtaFragmentBlogPost
          ...TwoColsTextListFragmentBlogPost
        }
      }
    }
  }
`;
